import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

import SEO from "../components/SEO";

export default function About() {
  return (
    <>
      <SEO
        title={"Om oss"}
        description="Pluggdriven.se är en sida för att hjälpa dig med dina studier."
      />
      <Navbar />
      <div className="siteBackground">
        <div className="wrapper">
          <div className="whiteTextContainer aboutPage articleText s-min-height">
            <br />
            <h1>Om oss</h1>
            <p>Pluggdriven är en hemsida med syftet att hjälpa unga och studenter i deras studier. Vi publicerar innehåll inom områdena matematik, kemi, fysik och programmering.</p>
            <p>Pluggdriven startades eftersom vi ansåg att det inte fanns så mycket bra innehåll på webben med syftet att lära ut till gymnasiestudenter. Vi som publicerar innehåll på hemsidan heter Lucas och Niklas och vi är för närvarande masterstudenter på Chalmers Tekniska Högskola. </p>
            <p>Vi gick själva tidigare på natur och teknik under gymnasiet och vi tyckte alltid att det kunde vara svårt att hitta bra information när vi studerade. Det är något som vi vill ändra på och därför startade vi pluggdriven.</p>
            <p>Ha det gott!</p>
            <p>//Lucas och Niklas</p>
          </div>
        </div>
      </div>
      <Footer dark={true} />
    </>
  );
}
